import React from 'react';

const NumericStepper = ({ quantity, setQuantity }) => {

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  return (
    <div style={styles.stepperContainer}>
      <button
        onClick={() => handleQuantityChange(Math.max(1, quantity - 1))}
        style={styles.stepperButton}
      >
        <span style={styles.stepperButtonText}>-</span>
      </button>
      <span style={styles.quantityText}>{quantity}</span>
      <button
        onClick={() => handleQuantityChange(quantity + 1)}
        style={styles.stepperButton}
      >
        <span style={styles.stepperButtonText}>+</span>
      </button>
    </div>
  );
};

const styles = {
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperButton: {
    backgroundColor: '#ddd',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    margin: '5px',
  },
  stepperButtonText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  quantityText: {
    margin: '0 20px',
    fontSize: '16px',
  },
};

export default NumericStepper;
