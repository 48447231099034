import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Styles/Products.css';
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoToTop from '../Components/GoToTop';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { addToCart, showItemAddedPopup } = useCart(); // Make sure you have the useCart hook

  const handlePopUp = () => {
    navigate('/cart');
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/dispensaries/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddToCart = (product, event) => {
    event.stopPropagation(); // This stops the event from bubbling up
    const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
    console.log("Adding to cart:", productToAdd.id); 
    addToCart(productToAdd);
  };

  return (
    <div className="container">
    {showItemAddedPopup && (
        <div
          style={{
            fontWeight: '400',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: '#f1f1f1',
            color: '#111',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: '9999999',
            opacity: '0',
            transform: 'translateY(-20px)',
            transition: 'opacity 0.25s ease, transform 0.25s ease',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
          }}
        >
          Item added to cart!
          <p><button style={{
               fontWeight: '500',
            color: 'white', 
            backgroundColor: '#111', 
            borderRadius: '8px', 
            cursor: 'pointer',
             transition: 'background-color 0.3s ease',
            padding: '10px 20px'}
            } onClick={handlePopUp}>View Cart</button></p>
        </div>
      )}

      <h1>Products</h1>
      <input 
        type="text"
        placeholder="Search products..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <div className="product-grid">
        {filteredProducts.map((product, index) => (
          <div key={index} className="products-card" onClick={() => handleProductSelect(product)}>
            <img class="product-img" src={product.imageUrl} alt={product.name} />
            <h3 class="products-title">{product.name}</h3>
            <p class="products-price">${product.price}</p>
            <p class="products-thc">{product.thcPercentage}%</p>
            <p class="products-dispo">{product.dispensaryName}</p>  
            <button onClick={(e) => {
              e.stopPropagation();
              handleAddToCart(product, e);
            }} className="add-to-cart-button">
              <FontAwesomeIcon icon={faShoppingCart} />
            </button>
          </div>
        ))}
      </div>
      <GoToTop />
    </div>
  );
};

export default Products;
