import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Styles/Dispensary.css'; // Import your new CSS file here
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import GoToTop from '../Components/GoToTop';

const Dispensary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispensary = location.state?.dispensary;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const { addToCart, showItemAddedPopup } = useCart();

  const handlePopUp = () => {
    navigate('/cart');
  };

  useEffect(() => {
    if (dispensary && dispensary.id) {
      const fetchMenuItems = async () => {
        try {
          const response = await axios.get(`https://toke-ajla.onrender.com/dispensaries/${dispensary.id}/menu`);
          if (response.data && Array.isArray(response.data)) {
            setCategories(response.data);
            const allProducts = response.data.reduce((acc, category) => [...acc, ...category.items], []);
            setProducts(allProducts);
          } else {
            setCategories([]);
          }
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      };
      fetchMenuItems();
    }
  }, [dispensary]);

  const handleAddToCart = (product, event) => {
    event.stopPropagation(); // This stops the event from bubbling up
    const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
    addToCart(productToAdd);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setProducts(category.items);
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  return (
    <div className="dispensary-container">
      {showItemAddedPopup && (
        <div className="popup">
          Item added to cart!
          <button className="view-cart-button" onClick={handlePopUp}>View Cart</button>
        </div>
      )}
      <h2 className="dispensary-title">{dispensary.name}</h2>
      <div className="category-container">
        <h3 className="subtitle">Shop by Category</h3>
        <div className="scroll-container">
          {categories.map(category => (
            <div 
              key={category._id} 
              onClick={() => handleCategorySelect(category)} 
              className={`category-card ${selectedCategory === category ? 'active' : ''}`}
            >
              <p className="category-name">{category.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h3 className="subtitle">
          {selectedCategory ? `Products in ${selectedCategory.name}` : 'All Products'}
        </h3>
        <div className='products-container'>
          {products.map(product => (
            <div 
              key={product._id} 
              onClick={() => handleProductSelect(product)} 
              className="product-card"
            >
              <img src={product.imageUrl} alt={product.name} className="product-image" />
              <div>
                <p className="product-name">{product.name}</p>
                <p className="product-thc">{product.thcPercentage}% THC</p>
                <p className="product-price">${product.price}</p>
                   <p className="product-shop">${product.shop}</p>
              </div>
              <button 
                onClick={(e) => {
                  e.stopPropagation(); // Stop event from bubbling up
                  handleAddToCart(product, e);
                }} 
                className="add-to-cart-button"
              >
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          ))}
        </div>
      </div>
      <GoToTop />
    </div>
  );
};

export default Dispensary;