  import React from 'react';
import '../Styles/Privacy.css'
import GoToTop from '../Components/GoToTop';

const Privacy = () => {
  return (
    <div className="privacy-container">


  <h1>Privacy Policy</h1>
    <p>Last Updated: December 4, 2023</p>

    <h2>Introduction</h2>
    <p>Toke respects the privacy of our users and is committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information through the use of our website, mobile application, and services (collectively referred to as "Services"). By accessing or using Toke, you agree to this Privacy Policy.</p>

    <h2>Information Collection and Use</h2>
    <p>
        <li>Personal Information: We collect personal information you provide to us, such as your name, email address, postal address, phone number, and age for verification purposes. This information is used to facilitate your use of Toke.</li>
        <li>Usage Information: We collect information about how you use our Services, including your search queries, the pages you visit, and the date and time of your visits. This helps us improve and personalize your experience.</li>
        <li>Location Data: With your permission, we collect location data to provide location-based services such as finding the nearest cannabis stores.</li>
        <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to monitor and analyze usage trends and improve our Services.</li>
    </p>

    <h2>Sharing of Information</h2>
    <p>
        <li>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery.</li>
        <li>Legal Requirements: We may disclose your information if required by law or if we believe in good faith that such disclosure is necessary to comply with legal processes, enforce our policies, or protect our rights, property, or safety.</li>
        <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction.</li>
    </p>

    <h2>Security</h2>
    <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free.</p>

    <h2>Your Choices and Rights</h2>
    <p>
        <li>Access and Correction: You may access and update your personal information through your account settings.</li>
        <li>Opt-Out: You can opt-out of receiving promotional emails from us by following the unsubscribe instructions provided in those emails.</li>
        <li>Cookies: You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent.</li>
    </p>

    <h2>Changes to This Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the “Last Updated” date.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@tokerunner.com" target='_blank'> hello@trytoke.com</a>.</p>
        <GoToTop />
        </div>
          )
}

export default Privacy;
