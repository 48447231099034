import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import '../Styles/OrderHistory.css'; // Make sure this path is correct
import GoToTop from '../Components/GoToTop';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (user) {
          const response = await axios.get(`https://toke-ajla.onrender.com/orders/user/${user.uid}`);
          setOrders(response.data);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, [user]);

  return (
    <div className="order-history-container">
      <h2 className="order-history-header">Order History</h2>
      {orders.map((order, index) => (
        <div key={index} className="order-item">
          <p>Total Amount: ${order.totalAmount}</p>
          <p>Items Ordered:</p>
          {order.items.map((item, idx) => (
            <div key={idx} className="item-details">
              <img src={item.imageUrl} alt={item.name} className="item-image" />
              <p className="item-description">{item.name} - Quantity: {item.quantity} - Price: ${item.price}</p>
            </div>
          ))}
        </div>
      ))}
      <GoToTop />
    </div>
  );
};

export default OrderHistory;
