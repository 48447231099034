//stylesheet
import './App.css';
import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-Medium.ttf';
import './assets/fonts/Poppins-Bold.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins-Light.ttf';

import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import RootNavigator from './Routes/RootNavigator';
import { CartProvider } from './Context/CartContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AgeVerificationModal from './Components/AgeVerificationModal'

const stripePromise = loadStripe('pk_live_51LXvSmHkD5ALgn7ny0yJLpewAFTV8gz5J6oYqflsmSQxq1gTBJwc1xhDtWQR2c3j2HWdGd8298WBUdIymUS64Qdv00SWuKdgQA')

function App() {
  const [ageVerified, setAgeVerified] = useState(false);

  useEffect(() => {
    ReactGA.initialize('G-9C8130C1YL');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  useEffect(() => {
    // Check if age has already been verified
    const isAgeVerified = localStorage.getItem('ageVerified') === 'true';
    setAgeVerified(isAgeVerified);
  }, []);

  const handleAgeVerification = (isVerified) => {
    localStorage.setItem('ageVerified', isVerified);
    setAgeVerified(isVerified);
    if (!isVerified) {
      // Handle the scenario when the user is not 21 or older
      // For example, show an information message or redirect
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <CartProvider>
        <Router>
          <div className='app-container'>
            {!ageVerified && <AgeVerificationModal onVerify={handleAgeVerification} />}
            <RootNavigator />
          </div>
        </Router>
      </CartProvider>
    </Elements>
  )
}

export default App;