import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [showItemAddedPopup, setShowItemAddedPopup] = useState(false);

  const removeFromCart = (itemId) => {
    setCartItems((currentItems) =>
      currentItems.filter((item) => item._id !== itemId)
    );
    console.log(itemId);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    const updatedCartItems = cartItems.map((item) =>
      item._id === itemId
        ? { ...item, quantity: item.quantity + newQuantity } // Increment or decrement the quantity
        : item
    );
  
    // Ensure quantity is at least 1
    updatedCartItems.forEach((item) => {
      if (item.quantity < 1) {
        item.quantity = 1;
      }
    });
  
    setCartItems(updatedCartItems);
    // Update local storage or send API request to update cart
  };

  useEffect(() => {
    const loadCartItems = () => {
      const storedCartItems = localStorage.getItem('cartItems');
      if (storedCartItems) {
        setCartItems(JSON.parse(storedCartItems));
      }
    };

    loadCartItems();
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (newItem) => {
    setCartItems((currentItems) => {
      // Check if the cart already contains items from a different dispensary
      const differentDispensaryExists = currentItems.some(
        (item) => item.dispensaryId !== newItem.dispensaryId
      );
  
      if (differentDispensaryExists) {
        // Prevent adding the item and alert the user
        alert(
          'Your cart contains items from a different dispensary. Please complete that purchase or clear your cart to add items from a new dispensary.'
        );
        return currentItems;
      }
  
      // If the dispensary is the same, proceed to add or update the item in the cart
      const existingItemIndex = currentItems.findIndex(
        (item) => item._id === newItem._id
      );
      if (existingItemIndex > -1) {
        let updatedItems = [...currentItems];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + newItem.quantity,
        };
  
        // Show the item added popup
        setShowItemAddedPopup(true);
  
        // Hide the popup after 2 seconds
        setTimeout(() => {
          setShowItemAddedPopup(false);
        }, 2000);
  
        return updatedItems;
      } else {
        // Show the item added popup
        setShowItemAddedPopup(true);
  
        // Hide the popup after 2 seconds
        setTimeout(() => {
          setShowItemAddedPopup(false);
        }, 2500);
  
        return [...currentItems, newItem];
      }
    });
  };
  

const clearCart = () => {
  setCartItems([]);
};
  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart, showItemAddedPopup, handleQuantityChange }}>
      {children}
    </CartContext.Provider>
  );
};