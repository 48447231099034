import React from 'react';
import '../Styles/Terms.css'
import GoToTop from '../Components/GoToTop';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p>Updated December 4, 2023</p>
      
      <h2>Our Policy</h2>
      <p>This website, mobile application, and services rendered through Toke Technologies, Inc. (collectively referred to as "Toke") are intended to facilitate the legal, safe, and responsible use of cannabis products. Toke does not promote, condone, or advocate licit or illicit drug use. Toke cannot be held responsible for any material from our website or app, or linked to from our website or app, that promotes illegal activities. Our services are designed to support and provide a platform for legal cannabis service. Any misuse or illicit use of our services is strictly against our terms and conditions.</p>

      <h2> Disclaimer & Acknowledgement</h2>
      <p> THE COMPANY IS NOT A RECREATIONAL OR MEDICAL CANNABIS COLLECTIVE OR COOPERATIVE ("DISPENSARY"). THE COMPANY DOES NOT PROVIDE OR SELL RECREATIONAL & MEDICAL CANNABIS. IT IS THE SOLE RESPONSIBILITY OF THE THIRD PARTY DISPENSARY TO OFFER STATE LAW COMPLIANT SERVICES, WHICH MAY BE LOCATED, SCHEDULED, AND COORDINATED THROUGH USE OF THE APPLICATION OR SERVICE. Toke offers pre-verified, licensed cannabis Retailers the ability to offer their services to you through the Toke.

        Toke does not endorse nor support the use of cannabis and has no responsibility or liability for any Services provided to you by Retailers or their Drivers, or provided by you as a Driver to Consumers.
        You expressly agree and acknowledge that Toke (i) is a technology services provider; (ii) is not a licensed medical or adult-use cannabis Retailer, Brand (defined below in “Third-Party Providers”), or cultivator; (iii) does not itself provide Services to Consumers; (iv) does not employ or contract Drivers who provide Services; and (v) does not cultivate, manufacture, package, label, test, or sell medical or adult-use cannabis. It is the sole responsibility of the Retailer to offer its services to you in a legal and compliant manner, which are facilitated through its use of the Toke.
      </p>

      <h2>Legal Compliance</h2>
      <p>In accordance with California Health & Safety Codes, possession, sale, or service of marijuana beyond the legally permissible limits and/or without a state licensed permit is subject to penalties, including imprisonment and fines (§ 11362.1(a)(1), § 11357(c), § 11357(a)(1), § 11357(b)(1)(2), § 11359(a), § 11357(b)(1), § 11360(a)(1)(2), § 11360(b)(1), § 11362.1(a)(1)(2)(5), § 11359(b)). However, gifting of marijuana up to one ounce without remuneration is legal. Specific penalties apply for persons under 18 years of age. Toke partners with retail cannabis shops and uses independent contractors for service. The gifting law allows drivers to gift up to half the legal limit (28.5 grams) of marijuana. The cost paid by customers covers the in-store transaction, the scanning of identification, and the verification of the correct product order and appropriate pricing by the drivers.</p>

      <h2>User Responsibility</h2>
      <p>All users of Toke warrant their compliance with all applicable laws, including maintaining all necessary permits, licenses, and approvals to conduct a cannabis business. Users affirm that they will not engage in activities contrary to law, such as distributing cannabis to minors, diverting cannabis to states where it's illegal, or using their business as a cover for illegal activities (§ 11362.1(a)(1)(2)(5)).</p>

      <h2>Information and Usage</h2>
      <p>Toke is a software technology company providing an online marketplace. All information contained on Toke is for informational and educational purposes only. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</p>

      <h2>Liability and Risk</h2>
      <p>Toke is not responsible for the actions, products, or content of sellers, advertisers, or other users. By using our platform, you understand and agree that Toke is not liable for any claim, loss, or damage arising from the use of our services or from any content posted on our platform. You use Toke at your own risk. Please remember that the use of cannabis is a personal responsibility. We encourage all of our users to educate themselves about the potential risks and side effects of cannabis use and to make decisions that promote their health and well-being. By using Toke, you confirm that you understand and agree to this disclaimer. If you do not agree with these terms, please do not use our services.</p>

      <p>If you have any additional questions or concerns about this Policy or our information practices, please feel free to contact us at any time at hello@trytoke.com.</p>
      <GoToTop />
    </div>
  )
}

export default Terms;
