import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { useCart } from '../Context/CartContext'; // Import useCart hook
import '../Styles/Checkout.css'
import GoToTop from "../Components/GoToTop";
import { auth } from '../firebaseConfig'
import stripeIcon from '../assets/stripe.png'
import paypal from '../assets/paypal.png'

const Checkout = () => {
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isNewUserInfo, setIsNewUserInfo] = useState(false);
  const [activeTab, setActiveTab] = useState('stripe'); // Default to PayPal
  const location = useLocation();
  const { subtotal, deliveryFee, taxesAndFees, total } = location.state || {};
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [editAddress, setEditAddress] = useState(false); // Tracks if we're editing an existing address
  const [editPhone, setEditPhone] = useState(false); // Tracks if we're editing an existing address
  const [address, setAddress] = useState('');
  const [userName, setUserName] = useState('')
  const addressInputRef = useRef(null); // Reference for the address input;
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Fetch user's address
          const addressResponse = await axios.get(`https://toke-ajla.onrender.com/addresses/${user.uid}`);
          if (addressResponse.data.address) {
            setAddress(addressResponse.data.address);
            setEditAddress(true);
          } else {
            setEditAddress(false);
          }
  
          // Fetch user's phone number
          const phoneResponse = await axios.get(`https://toke-ajla.onrender.com/phone-numbers/${user.uid}`);
          if (phoneResponse.data.phoneNumber) {
            setPhoneNumber(phoneResponse.data.phoneNumber);
            setEditPhone(true);
          } else {
            setEditPhone(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          alert('Error: Failed to fetch user data');
        }
      }
    };
  
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
        document.head.appendChild(script);
        script.addEventListener('load', () => initializeAutocomplete(addressInputRef.current));
      } else {
        initializeAutocomplete(addressInputRef.current);
      }
    };
  
    const initializeAutocomplete = (input) => {
      if (input && window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.formatted_address) {
            setAddress(place.formatted_address);
          }
        });
      }
    };
  
    fetchUserData();
    loadGoogleMapsScript();
  }, [user]);
  
  const {clearCart, cartItems } = useCart()
  useEffect(() => {
    if (user) {
      setUserName(user.displayName);  
    } else {
      setUserName('to Toke')
    }
  }, [user]);

  const handleSave = async () => {
    const userId = user.uid;
    try {
      if (editAddress) {
        // Updating an existing address
        await axios.put(`https://toke-ajla.onrender.com/addresses/${user.uid}`, { address });
        alert('Success: Address updated successfully');
      } else {
        // Adding a new address
        await axios.post('https://toke-ajla.onrender.com/addresses', { userId, address, fullName: userName });
        alert('Success: Address added successfully');
        setEditAddress(true); // Switch to edit mode after adding new address
      }
    } catch (error) {
      console.error('Error saving address:', error);
      alert('Error: Failed to save address');
    }
  };

  const handleSavePhoneNumber = async () => {
    const userId = user.uid;
    try {
      if (editPhone) {
        // Updating an existing phone number
        await axios.put(`https://toke-ajla.onrender.com/phone-numbers/${user.uid}`, { phoneNumber, fullName: userName });
        alert('Success: Phone number updated successfully');
      } else {
        // Adding a new phone number
        await axios.post('https://toke-ajla.onrender.com/phone-numbers', { userId, phoneNumber, fullName: userName });
        alert('Success: Phone number added successfully');
        setEditPhone(true); // Switch to edit mode after adding a new phone number
      }
    } catch (error) {
      console.error('Error saving phone number:', error);
      alert('Error: Failed to save phone number');
    }
  };

  const isPhoneNumberValid = (number) => {
    // Pattern for phone number with or without hyphens
    const pattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
    return pattern.test(number);
  };
  
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setActiveTab(method);
  };

  // PayPal Payment Handlers
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: total?.toFixed(2),
        },
      }],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log("Payment Successful:", details);
      finalizeOrder(details.id);
    });
  };

  const onError = (error) => {
    console.error("Payment Error:", error);
    alert("Payment Error: " + error.message);
  };

  const handlePlaceOrderStripe = async () => {
    if (!stripe || !elements) {
      return;
    }

    // Convert cart items to the format expected by your backend
    const orderItems = cartItems.map(item => ({
      productId: item._id,
      name: item.name,
      imageUrl: item.imageUrl,
      quantity: item.quantity,
      price: item.price,
    }));

    try {
      const totalInCents = Math.round(total * 100);
      const response = await fetch(
        "https://toke-ajla.onrender.com/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            total: totalInCents,
            items: orderItems,
            totalAmount: total,
            customerName: userName,
            userId: user ? user.uid : null,
          }),
        }
      );
      const responseData = await response.json();
      const { clientSecret } = responseData;

      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        console.error("Payment confirmation error", error);
        alert("Payment Error: " + error.message);
      } else if (paymentIntent) {
        finalizeOrder(responseData.orderId);
      }
    } catch (error) {
      console.error("Error", error);
      alert("Failed to create payment intent");
    }
  };

 const finalizeOrder = async (orderId) => {
  try {
    // Fetch the latest user data before finalizing the order
    const userId = user ? user.uid : null;
    let latestUserAddress = "";
    let latestPhoneNumber = "";

    if (userId) {
      const addressResponse = await axios.get(`https://toke-ajla.onrender.com/addresses/${userId}`);
      latestUserAddress = addressResponse.data.address;

      const phoneResponse = await axios.get(`https://toke-ajla.onrender.com/phone-numbers/${userId}`);
      latestPhoneNumber = phoneResponse.data.phoneNumber;
    } 

    // Proceed with finalizing the order
    await axios.post("https://toke-ajla.onrender.com/finalize-paypal-order", {
      orderId: orderId,
      orderDetails: {
        subtotal,
        deliveryFee,
        taxesAndFees,
        total,
        userId: user ? user.uid : null,
        address: latestUserAddress,
        phoneNumber: latestPhoneNumber,
        items: cartItems.map(item => ({
          productId: item._id,
          name: item.name,
          imageUrl: item.imageUrl,
          quantity: item.quantity,
          price: item.price,
        })),
        customerName: userName
      },
    });

    // Set order as placed
    setOrderPlaced(true);
    alert("Order finalized successfully!");
    clearCart();

    // Prepare the description for the SMS message
    const orderedItemsDescription = cartItems.map(item => 
      `${item.quantity} x ${item.name} from ${item.dispensaryName}`).join(', ');

    // Prepare the SMS data
    const smsData = {
      message: `Toke order from ${userName} for a total of $${total.toFixed(2)}. They ordered ${orderedItemsDescription} and need it delivered to ${latestUserAddress}. Phone: ${latestPhoneNumber}`,
      toNumbers: ['+19492782418', '+19498422889']
    };

    // Send SMS notification
    const smsResponse = await axios.post('https://toke-ajla.onrender.com/send-sms', smsData);
    console.log("SMS Response:", smsResponse.data);

  } catch (error) {
    console.error("Error finalizing order:", error);

    if (error.isAxiosError) {
      console.error("Axios error details:", {
        status: error.response?.status,
        data: error.response?.data,
      });
    }

    alert("Failed to finalize the order: " + (error.response ? error.response.data : error.message));
  }
};

  const handleTrackOrder = () => {
    navigate("/track-order");
  };

  return (
    <div className="checkout-container">
      <h1 className="checkout-header">Place Order</h1>
      {/* <div className="payment-tabs">
        <button onClick={() => handlePaymentMethodChange('stripe')} style={{margin: '10px', borderRadius: '10px'}}><img src={stripeIcon} style={{height: '30px', width: 'auto', margin: '10px'}}/></button>

        <button onClick={() => handlePaymentMethodChange('paypal')} style={{margin: '10px', borderRadius: '10px'}}><img src={paypal} style={{height: '30px', width: 'auto', margin: '10px'}}/></button>
      </div> */}

      {/* {activeTab === 'stripe' ? ( */}
        <div className="stripe-payment">
          <CardElement className="card-element" />
        </div>
      {/* ) : (
        <PayPalScriptProvider options={{ "client-id": "AZDRsffObiOcK2OUu5iCTVVpjyKQnf75XM2VjlLXw7VCKG_Va0K0YiHJDrtXG4X7m8tHMZ1not0eoK9r" }}>
          <div className="paypal-payment">
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
            />
          </div>
        </PayPalScriptProvider>
      )} */}

    
        <>
          {/* Display the input fields if the user doesn't have a phone number or address */}
          <div className="address-container">
            <div className="address-header">{editAddress ? 'Your Address' : 'Add New Address'}</div>
            <textarea
              ref={addressInputRef} // Attach the reference here
              className="address-input"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={!editAddress ? 'Enter your new address here' : ''}
            />
            <button className="address-button" onClick={handleSave}>
              {editAddress ? 'Save Address' : 'Add Address'}
            </button>
         </div>
        </>
        <>
  {/* Display the input fields if the user doesn't have a phone number or address */}
        <div className="address-container">
          <div className="address-header">{editPhone ? 'Your Phone Number' : 'Add New Phone Number'}</div>
          <input
            className="address-input" // You can use the same class as address-input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={!editPhone ? 'Enter your new phone number here' : ''}
          />
          <button className="address-button" onClick={handleSavePhoneNumber}>
            {editPhone ? 'Save Phone Number' : 'Add Phone Number'}
          </button>
        </div>
      </>
     

      <p className="phone-number-text">Phone Number: {phoneNumber}</p>
      <p className="address-text">Delivering to: {address}</p>
      <p>Subtotal: ${subtotal?.toFixed(2)}</p>
      <p>Service Fee: ${deliveryFee?.toFixed(2)}</p>
      <p>Tax: ${taxesAndFees?.toFixed(2)}</p>
      <p>Total: ${total?.toFixed(2)}</p>

      {!orderPlaced ? (
        <button onClick={activeTab === 'stripe' ? handlePlaceOrderStripe : onApprove} className="place-order-button">
          Place Order
        </button>
      ) : (
        <button onClick={handleTrackOrder} className="track-order-button">
          Track Order
        </button>
      )}
      <GoToTop />
    </div>
  );
};

export default Checkout;
