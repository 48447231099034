import React, { useState, useEffect } from "react";
import axios from "axios";
import '../Styles/Checkout.css'
import { auth } from '../firebaseConfig'

const PhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const user = auth.currentUser;
  const [editPhone, setEditPhone] = useState(false); // Tracks if we're editing an existing address
  const [userName, setUserName] = useState('')
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
         
          // Fetch user's phone number
          const phoneResponse = await axios.get(`https://toke-ajla.onrender.com/phone-numbers/${user.uid}`);
          if (phoneResponse.data.phoneNumber) {
            setPhoneNumber(phoneResponse.data.phoneNumber);
            setEditPhone(true);
          } else {
            setEditPhone(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          alert('Error: Failed to fetch user data');
        }
      }
    };
  
    fetchUserData();
  }, [user]);

    useEffect(() => {
        if (user) {
          setUserName(user.displayName);  
        } else {
          setUserName('to Toke')
        }
      }, [user]);

    const handleSavePhoneNumber = async () => {
        const userId = user.uid;
        try {
          if (editPhone) {
            // Updating an existing phone number
            await axios.put(`https://toke-ajla.onrender.com/phone-numbers/${user.uid}`, { phoneNumber, fullName: userName });
            alert('Success: Phone number updated successfully');
          } else {
            // Adding a new phone number
            await axios.post('https://toke-ajla.onrender.com/phone-numbers', { userId, phoneNumber, fullName: userName });
            alert('Success: Phone number added successfully');
            setEditPhone(true); // Switch to edit mode after adding a new phone number
          }
        } catch (error) {
          console.error('Error saving phone number:', error);
          alert('Error: Failed to save phone number');
        }
      };
  return (
    <>
  {/* Display the input fields if the user doesn't have a phone number or address */}
        <div className="address-container">
          <h2 className="address-header">{editPhone ? 'Your Phone Number' : 'Add New Phone Number'}</h2>
          <input
            className="address-input" // You can use the same class as address-input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder={!editPhone ? 'Enter your new phone number here' : ''}
          />
          <button className="address-button" onClick={handleSavePhoneNumber}>
            {editPhone ? 'Save Phone Number' : 'Add Phone Number'}
          </button>
        </div>
      </>
  )
}

export default PhoneNumber