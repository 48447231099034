import React from 'react';
import '../Styles/About.css'; // Import your CSS file for styling
import GoToTop from '../Components/GoToTop';

const About = () => {
    return (
        <div className="about-container">
            <section className="about-header">
                <h1>About Us</h1>
            </section>

            <section className="about-content">
                <h2>Introducing Toke</h2>
                <p>
                  Toke is a cannabis delivery platform that connects consumers to local dispensaries. We provide a seamless, on-demand experience that is faster, more convenient, and more reliable than traditional delivery services.
                </p>

                <h2>Our Mission</h2>
                <p>
                Founded in 2023 with a vision of revolutionizing the cannabis experience by seamlessly bridging the gap between customers, local gig-drivers, and licensed dispensaries. Our vision is to provide unparalleled convenience and access to cannabis products while empowering local gig-workers and businesses. Join us on our mission to transform the cannabis industry and elevate your cannabis shopping experience.
                </p>

                <h2>Our Values</h2>
                <p>
                    At Toke, we are committed to being a responsible and trusted member of the cannabis community, working closely with local dispensaries and growers to ensure a reliable supply of products, and promoting education and advocacy around the safe and responsible use of cannabis. Quality, innovation, and customer satisfaction are at the core of everything we do. 
                 
                </p>

                <h2>Our Team</h2>
                <p>
                    Have questions or feedback? We'd love to hear from you.
                    Reach out to us <a href='/contact'>here</a>.
                </p>
            </section>
            <GoToTop />
        </div>
    );
}

export default About;