import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import hero from '../assets/hero.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faShoppingCart, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../Styles/Home.css'
// import NumericStepper from '../Components/numericStepper'; // Adjust path as needed
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Shop from '../assets/howitworks-1.png';
import Pay from '../assets/howitworks-2.png';
import Enjoy from '../assets/howitworks-3.png';
import useDraggableScroll from '../Components/useDraggableScroll';
import GoToTop from '../Components/GoToTop';
import { auth } from '../firebaseConfig';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeScreen = () => {
  const [userName, setUserName] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [products, setProducts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState(['']);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('All Products');
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [dispensaries, setDispensaries] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingDispensaries, setLoadingDispensaries] = useState(true);
  const [loadingDeals, setLoadingDeals] = useState(true);
  const { addToCart, showItemAddedPopup } = useCart(); // Make sure you have the useCart hook
  const { onMouseDown, onMouseMove, onMouseUp, isDragging } = useDraggableScroll();

  const faqs = [
     { question: 'What is Toke?', answer: 'Toke is a cannabis platform that introduces on demand delivery that is faster, more convenient & safer than traditional delivery services.', open: false },
    { question: 'How do I order?', answer: 'You can order directly here. First add your products, then pay online using your debit/credit card, and finally relax while your order arrives within 15 - 30 minutes.', open: false },
     { question: 'How much does it cost?', answer: 'We do not charge delivery fees for orders over $25. Orders less than $25 will have a service fee varying based on your location.', open: false },
      { question: 'How long does it take?', answer: 'Orders arrive on average in just 15 - 35 minutes.', open: false },
       { question: 'Where does Toke operate?', answer: 'We are currently operating Orange County, CA (Irvine, Costa Mesa, Newport Beach, Santa Ana, Anaheim, Tustin, Laguna Beach, Huntington Beach).', open: false },
       { question: 'What if there is a problem with my order?', answer: 'Please contact our customer service (949.942.2889 or hello@trytoke.com) & we will assist you immediately.', open: false },
      //  { question: 'I want to drive for Toke', answer: 'Please contact our customer service (949.942.2889 or hello@trytoke.com) & we will assist you immediately.', open: false },
       
    // Add more FAQs here
  ];

  const AccordionItem = ({ faq, index, toggleFAQ }) => (
    <div className={`accordion-item ${faq.open ? 'open' : ''}`}>
      <div className="accordion-title" onClick={() => toggleFAQ(index)}>
      {faq.question}
      <div className={`accordion-icon ${faq.open ? 'open' : ''}`}>
        {faq.open ? <FaChevronUp /> : <FaChevronDown />}
      </div>
    </div>
      <div className="accordion-content">
        {faq.answer}
      </div>
    </div>
  );

  const FAQAccordion = () => {
    const [faqsState, setFaqsState] = useState(faqs);

    const toggleFAQ = index => {
      const newFaqs = faqsState.map((faq, i) => {
        if (i === index) {
          return {...faq, open: !faq.open};
        } 
        return {...faq, open: false};
      });

      setFaqsState(newFaqs);
    };

    return (
      <div className="faq-accordion">
        {faqsState.map((faq, index) => (
          <AccordionItem
            key={index}
            faq={faq}
            index={index}
            toggleFAQ={toggleFAQ}
          />
        ))}
      </div>
    );
  };
  const handleAddToCart = (product, event) => {
   event.stopPropagation(); // This stops the event from bubbling up
    const productToAdd = { ...product, quantity: 1 }; // Default quantity set to 1
    console.log("Adding to cart:", productToAdd.id); 
    addToCart(productToAdd);
  };
  

  const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ latitude, longitude });
  };

  const handleLocationError = (error) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  };

  const fetchDispensaries = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/dispensaries');
      setDispensaries(response.data);
    } catch (error) {
      console.error('Error fetching dispensaries:', error);
    }
  };

  useEffect(() => {
    if (user) {
      setUserName(user.displayName);  
    } else {
      setUserName('to Toke')
    }
    fetchUserAddress();
    fetchProducts();
    fetchDeals();
    fetchBrands();
    fetchCategories();
    fetchDispensaries()
    setLoadingDeals(false)
    setLoadingDispensaries(false)
    setLoadingProducts(false)
  }, [user]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProducts = async (category = '') => {
    try {
      const url = category 
        ? `https://toke-ajla.onrender.com/dispensaries/products?category=${category}`
        : 'https://toke-ajla.onrender.com/dispensaries/products';
      const response = await axios.get(url);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchFlower = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/categories/flower');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching Flower products:', error);
    }
  };
  
  const fetchPens = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/categories/pens');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching Pens products:', error);
    }
  };

  const fetchConcentrates = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/categories/concentrates');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching Concentrates products:', error);
    }
  };
  
  const fetchEdibles = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/categories/edibles');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching Edibles products:', error);
    }
  };  

  const handleDispensarySelect = (dispensary) => {
    navigate('/dispensary', { state: { dispensary: { ...dispensary, id: dispensary._id } } });
  };

  const fetchDeals = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/dispensaries/deals');
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await axios.get('https://toke-ajla.onrender.com/dispensaries/brands');
      setBrands(response.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchUserAddress = async () => {
    if (user) {
      try {
        const response = await axios.get(`https://toke-ajla.onrender.com/addresses/${user.uid}`);
        setUserAddress(response.data.address);
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    }
  };

  const handlePopUp = () => {
    navigate('/cart');
  };

  const handleProductSelect = (product) => {
    navigate('/product', { state: { product } });
  };

  const handleCategorySelect = (categoryName) => {
    switch (categoryName) {
      case 'All Products':
        fetchProducts();
        setActiveCategory('All Products')
        break;
      case 'Flower':
        fetchFlower();
        setActiveCategory('Flower')
        break;
      case 'Vaporizers':
        fetchPens();
        setActiveCategory('Vaporizers')
        break;
      case 'Concentrates':
        fetchConcentrates();
        setActiveCategory('Concentrates')
        break;
      case 'Edibles':
        fetchEdibles();
        setActiveCategory('Edibles')
        break;
      default:
        fetchProducts();
        setActiveCategory('All Products')
    }
  };

  return (
      <div className="home-container">
          <section className="hero-section" style={{ backgroundImage: '././assets/hero.png' }}>
          <div className="text-container">
            <h1 className="header">On demand cannabis.</h1>
            <h1 className="subheader">15-30 min arrival time.<br />No fees for orders over $25.
         </h1>
             <h1 className="subheader"></h1>
             <div className="button-container">
                {/* <button onClick={() => window.location.href='/products'}>All Products</button> */}
                {/* <button onClick={() => window.location.href='/shops'}> Order Now </button> */}
              </div>
          </div>
        </section>

        {showItemAddedPopup && (
        <div
          style={{
            fontWeight: '400',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: '#f1f1f1',
            color: '#111',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: '9999999',
            opacity: '0',
            transform: 'translateY(-20px)',
            transition: 'opacity 0.25s ease, transform 0.25s ease',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
          }}
        > Added to cart!
          <p>
            <button style={{
            fontWeight: '500',
            color: 'white', 
            backgroundColor: '#111', 
            borderRadius: '8px', 
            cursor: 'pointer',
             transition: 'background-color 0.3s ease',
            padding: '10px 20px'}} onClick={handlePopUp}> View Cart </button>
            </p>
        </div>
      )}

        <section className="info-container">
          <div className="welcome-container">
            <h2 style={{textAlign: 'center', fontSize: '16px', fontWeight:'400'}} className="user-name">Welcome {userName}</h2>
            {/* <p className="user-address">{userAddress}</p> */}
          </div>

          <div className="categories-container">
          {['All Products', 'Flower', 'Vaporizers', 'Concentrates', 'Edibles'].map((category, index) => (
            <button 
              key={index} 
              onClick={() => handleCategorySelect(category)}
              className={`category-button ${activeCategory === category ? 'active' : ''}`}
            >
              {category}
            </button>
         ))}
      </div>

    <div className="featured-container">
      <h2 className="section-title"> Products <a href="/products" className="view-all"> View All </a> </h2>
      <div
        className="product-container"
        style={{ display: 'flex', overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
        onMouseUp={onMouseUp}
      >
        {loadingDeals ? (
          <div className="loading-icon">
            <FontAwesomeIcon style={{width: '25px', height: '25px'}} icon={faSpinner} spin />
          </div>
        ) : (
          products
            .filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(filteredProduct => (
              <div key={filteredProduct.id} onClick={() => handleProductSelect(filteredProduct)} className="product-card" style={{ flex: '0 0 auto', margin: '0 10px' }}>
                <img src={filteredProduct.imageUrl} alt={filteredProduct.name} className="product-image" />
                <p className="product-name">{filteredProduct.name}</p>
                <p className="product-thc">{filteredProduct.thcPercentage}% THC</p>
                <p className="product-shop">{filteredProduct.dispensaryName}</p>
                <p className="product-price">${filteredProduct.price}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(filteredProduct, e);
                  }}
                  className="add-to-cart-button"
                >
                  <FontAwesomeIcon icon={faShoppingCart} />
                </button>
              </div>
            ))
        )}
      </div>
    </div>



    <div className="featured-container">
      <h2 className="section-title">Shops Near You <a href="/shops" className="view-all">View All</a> </h2>
      <div
        className="product-container"
        style={{ display: 'flex', overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
        onMouseUp={onMouseUp}
      >
        {loadingDeals ? (
          <div className="loading-icon">
            <FontAwesomeIcon icon={faSpinner} spin />
            Loading...
          </div>
        ) : (
          dispensaries.map((dispensary) => (
            <div key={dispensary.id} onClick={() => handleDispensarySelect(dispensary)} className="shop-card" style={{ flex: '0 0 auto', margin: '0 30px 5px', paddingBottom:'5px' }}>
              <img src={dispensary.imageUrl} alt={dispensary.name} className="product-image" />
              <p className="product-price">{dispensary.rating}/5★</p>
              {/* Add more details as needed */}
            </div>
          ))
        )}
      </div>
    </div>

 

    <div className="featured-container">
      <h2 className="section-title">Current Deals<a href="/products" className="view-all"> View All </a></h2>
      <div className="product-container" style={{ display: 'flex', overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}>
        {loadingDeals ? (
          <div className="loading-icon">
            <FontAwesomeIcon icon={faSpinner} spin />
            Loading...
          </div>
        ) : (
          deals.map((deal) => (
            <div key={deal.id} onClick={() => handleProductSelect(deal)} className="product-card" style={{ flex: '0 0 auto', margin: '0 10px 5px' }}>
              <img src={deal.imageUrl} alt={deal.name} className="product-image" />
              <p className="product-name">{deal.name}</p>
              <p className="product-thc">{deal.thcPercentage}% THC</p>
              <p className="product-shops">{deal.dispensaryName}</p>
              <p className="product-price">${deal.price}</p>
              <button onClick={(e) => handleAddToCart(deal, e)} className="add-to-cart-button">
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          ))
        )}
      </div>
    </div>

  
    <div className="image-banner">
        {/* <div className="section-title">Get Started<br /></div>   */}
        <button onClick={() => navigate('/products')}>Shop Products</button>
    </div>

{ /* HOW IT WORKS Slider  */}
    {/* <div className="how-it-works-section">
      <h2 className="section-title"> How It Works </h2>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={3000}
        arrows={false}
      >
        <div className="how-it-works-step">
          <h3>1. Shop</h3>
          <img src={Shop} alt="Shop" className="step-image" />
          <p>Shop & explore our wide range of cannabis products from the comfort & safety of your own home.</p>
        </div>
        <div className="how-it-works-step">
          <h3>2. Pay</h3>
          <img src={Pay} alt="Pay" className="step-image" />
          <p>Use TokePay to pay online with credit/debit cards with no extra fees, or opt for cash on arrival.</p>
        </div>
        <div className="how-it-works-step">
          <h3>3. Enjoy</h3>
          <img src={Enjoy} alt="Enjoy" className="step-image" />
          <p>Sit back and relax as your order arrives within 15-35 minutes average.</p>
        </div>
      </Slider>
    </div> */}


      <h2 className="section-title"> How It Works </h2> 
      <section className="how-it-works-container">
        <div className="how-it-works-step">
          <h3>1. Shop</h3>
          <img src={Shop} alt="Shop" className="step-image" />
          <p>Shop products from the comfort & safety of your location.</p>
        </div> 
        <div className="how-it-works-step">
          <h3>2. Pay</h3>
          <img src={Pay}  alt="Shop" className="step-image" />
          <p>Pay online using your credit/debit card with no extra fees.</p>
        </div>
        <div className="how-it-works-step">
          <h3>3. Enjoy</h3>
          <img src={Enjoy} alt="Shop" className="step-image" />
          <p>Relax as your order arrives within 15-30 minutes average.</p>
        </div>
      </section>

       <section className="faq-section">
        <h2 className="section-title">FAQ</h2>
        <FAQAccordion />
      </section>


 

    {/* subscribe section */}
      {/* <section className="email-subscription"> */}
      {/* <h2>Subscribe to Our Newsletter</h2>
      <p>Get the latest updates and deals directly to your inbox.</p>
      <div className="email-subscription-form">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Subscribe</button>
      </div>
    </section> */}


      </section>
      <GoToTop />
    </div>
  );
};


export default HomeScreen;