import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import '../Styles/BottomNavigator.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchScreen from '../Pages/Search';
import { faMapMarkerAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import userIcon from '../assets/icon-user.svg';
import cartIcon from '../assets/icon-cart.svg';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../Context/CartContext';
import HomeScreen from '../Pages/Home';
import Dispensaries from '../Pages/Dispensaries';
import Product from '../Pages/Product';
import Products from '../Pages/Products';
import Dispensary from '../Pages/Dispensary';
import Cart from '../Pages/Cart';
import Checkout from '../Pages/Checkout'
import Profile from '../Pages/Profile';
import OrderHistory from '../Pages/OrderHistory'
import Address from '../Pages/Address'
import logo from '../assets/logo.png'; // Adjust the path to your logo
import About from '../Pages/About';
import Terms from '../Pages/Terms'
import Privacy from '../Pages/Privacy'
import { FaBars } from 'react-icons/fa';
import SignIn from '../Pages/SignIn';
import SignUp from '../Pages/SignUp';
import PasswordReset from '../Pages/PasswordReset';
import PrivateRoute from '../Components/PrivateRoute';
import Contact from '../Pages/Contact';
import PhoneNumber from '../Pages/PhoneNumber';
import { faShoppingBag, faUserCircle } from '@fortawesome/free-solid-svg-icons';


const BottomNavigator = () => {
  const navigate = useNavigate()
  const [userLocation, setUserLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userName, setUserName] = useState('')
  const [searchResults, setSearchResults] = useState([]);
  const user = auth.currentUser;
  const addressInputRef = useRef(null);

  const handleResultClick = (result) => {
    console.log('Navigating to:', result.type, 'with ID:', result._id);
    if (result.type === 'dispensary') {
      navigate('/dispensary', { state: { dispensary: { ...result, id: result._id } } });
    } else if (result.type === 'product') {
      navigate('/product', { state: { product: result } });
    }
    setSearchResults([]);
  };
  
  

  // Function to search products in dispensaries
const searchProductsInDispensaries = async (searchTerm) => {
  try {
    const response = await axios.get(`https://toke-ajla.onrender.com/search/dispensaries/products?term=${searchTerm}`);
    return response.data; // Array of products matching the search term
  } catch (error) {
    console.error('Error searching products:', error);
    return [];
  }
};

// Function to search dispensaries
const searchDispensaries = async (searchTerm) => {
  try {
    const response = await axios.get(`https://toke-ajla.onrender.com/search/dispensaries?term=${searchTerm}`);
    return response.data; // Array of dispensaries matching the search term
  } catch (error) {
    console.error('Error searching dispensaries:', error);
    return [];
  }
};

  const initializeAutocomplete = () => {
    if (addressInputRef.current && window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const address = place.formatted_address;
        setUserAddress(address);
        updateUserAddressInDatabase(address);
      });
    }
  };
  

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
        document.head.appendChild(script);
        script.addEventListener('load', initializeAutocomplete);
      } else {
        initializeAutocomplete();
      }
    };

    loadGoogleMapsScript();
  }, []);

  const updateUserAddressInDatabase = async (address) => {
    if (user && user.uid) {
      try {
        const response = await axios.put(`https://toke-ajla.onrender.com/addresses/${user.uid}`, { address });
        console.log(response.data.message); // Or handle this response as needed
      } catch (error) {
        console.error('Error updating address:', error);
      }
    } else {
      console.error('User ID is not available');
    }
  };  

  const handleSearchChange = async (event) => {
    setSearchTerm(event.target.value);
  
    if (event.target.value.length > 2) {
      const productResults = (await searchProductsInDispensaries(searchTerm)).map(product => ({ ...product, type: 'product' }));
const dispensaryResults = (await searchDispensaries(searchTerm)).map(dispensary => ({ ...dispensary, type: 'dispensary' }));
setSearchResults([...productResults, ...dispensaryResults]);

    } else {
      // Clear the search results
      setSearchResults([]);
    }
  };
  

   const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ latitude, longitude });
  };

  const handleLocationError = (error) => {
    console.warn(`ERROR(${error.code}): ${error.message}`);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  };
    const fetchUserAddress = async () => {
    if (user) {
      try {
        const response = await axios.get(`https://toke-ajla.onrender.com/addresses/${user.uid}`);
        setUserAddress(response.data.address);
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    }
  };
   useEffect(() => {
    if (user) {
      fetchUserAddress();
      setUserName(user.displayName || 'friend')
    }
  }, [user]);

   // Existing states and variables
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  
  const { cartItems } = useCart();
  const totalItemsInCart = cartItems.reduce((total, item) => total + item.quantity, 0);
    const [hoveredLink, setHoveredLink] = useState('');

  return (
    <div className="bottom-nav-container">
      <nav className="bottom-nav-bar">
      <Link to="/home" className="logo-link">
          <img src={logo} alt="Logo" className="logo-image" />
        </Link>
      <div className="search-address-container" style={{margin: '0px', padding: '0px', display: 'flex'}}>
          <div className="search-bar-container">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input 
              className="product-search"
              type="text"
              placeholder="Search product.." 
              value={searchTerm} 
              onChange={handleSearchChange}
            />
            {/* Dropdown for displaying search results */}
            {searchTerm && (
              <div className="search-results-dropdown">
              {searchResults.map((result, index) => (
                <div key={index} className="search-result-item" onClick={() => handleResultClick(result)}>
                  {result.name}
                </div>
              ))}
            </div>
            )}
          </div>

      <div className="address-bar">
        <button style={{border: 'none', background: 'transparent'}} onClick={() => navigate('/address')}><FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon"  /></button>
        <input 
          ref={addressInputRef}
          className='input-address'
          type="text" 
          placeholder="Enter address.." 
          value={userAddress} 
          onChange={(e) => setUserAddress(e.target.value)}
        />
      </div>

        </div>

        
        <div className="link-container">
          <Link 
            to="/home" 
            className='nav-link'
          > Home
          </Link>

          <Link 
            to="/shops" 
            className='nav-link'
          > Shops
          </Link>

          <Link 
            to="/products" 
            className='nav-link'
          > Products
          </Link>
        </div>
        
    <div className="icon-container">
      <Link 
        to="/cart" 
        className='nav-link'
      >
        <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
        <span className="cart-bubble">{totalItemsInCart}</span>
      </Link>
      {user ? (
        <Link 
          to="/profile" 
          className='nav-link'
        >
          <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
        </Link>
      ) : (
        <Link 
          to="/signin" 
          className='nav-link'
        >
          Sign In
        </Link>
      )}
    </div>
        
    </nav>
      
      <Routes>
        {/* Tab Routes */}
        <Route path="/home/*" index element={<HomeScreen />} />
        <Route path="/product" index element={<Product />} />
        <Route path="/shops/*" element={<Dispensaries />} />
        <Route path="/products" index element={<Products />} />
        <Route path="/dispensary" element={<Dispensary />} />
        <Route path="/search" element={<SearchScreen />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>} />
        <Route path="/order-history" element={<OrderHistory/>} />
        <Route path="/address" element={<Address/>} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/phone-number" element={<PhoneNumber />} />
        
      </Routes>
    </div>
  )
}

export default BottomNavigator;