import React from 'react';
import BottomNavigator from './BottomNavigator';
import Footer from '../Components/Footer';

function AppNavigator() {
  return (
    <>
      <BottomNavigator />
      <Footer />
    </>
)}

export default AppNavigator;
