// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpv8-hVA28pt11JvKP8GXezj__JqJOOs0",
  authDomain: "toke-customer-app-frontend.firebaseapp.com",
  projectId: "toke-customer-app-frontend",
  storageBucket: "toke-customer-app-frontend.appspot.com",
  messagingSenderId: "327178113125",
  appId: "1:327178113125:web:4d1a6b2de0d3d779ee368c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the authentication service
const auth = getAuth(app);

// Get a reference to the Firestore service
const db = getFirestore(app);




// Export the services
export { auth, db, app };
