import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../firebaseConfig'; // adjust the path as needed
import tokeLogo from '../assets/logo.png'
import googleLogo from '../assets/google-logo.png'
import GoToTop from '../Components/GoToTop';
import '../Styles/SignUp.css'

const SignUp = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false); // New state for the checkbox
    const addressInputRef = useRef(null); // Reference for the address input

    useEffect(() => {
        const loadGoogleMapsScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqU-88n3r2P341oK4s2YAsT0SAFT5W4u0&libraries=places`;
                document.head.appendChild(script);
                script.addEventListener('load', () => initializeAutocomplete(addressInputRef.current));
            } else {
                initializeAutocomplete(addressInputRef.current);
            }
        };

        const initializeAutocomplete = (input) => {
            if (input && window.google) {
                const autocomplete = new window.google.maps.places.Autocomplete(input);
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        setAddress(place.formatted_address);
                    }
                });
            }
        };

        loadGoogleMapsScript();
    }, []);

    const isPhoneNumberValid = (number) => {
        // Pattern for phone number with or without hyphens
        const pattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
        return pattern.test(number);
      };
      

    const handleGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate('/home');
            })
            .catch((error) => {
                alert('Error during Google Sign In: ' + error.message);
            });
    };

    const handleEmailSignUp = async () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        if (!isPhoneNumberValid(phoneNumber)) {
            alert('Invalid phone number format');
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
    
            const fullName = `${firstName} ${lastName}`;
            await updateProfile(userCredential.user, {
                displayName: fullName,
            });
    
            // Post address to the backend
            await axios.post('https://toke-ajla.onrender.com/addresses', { userId, address, fullName })
                .catch((error) => {
                    throw new Error(`Error saving address: ${error.message}`);
                });
    
            // Post phone number to the backend
            await axios.post('https://toke-ajla.onrender.com/phone-numbers', { userId, phoneNumber, fullName })
                .catch((error) => {
                    throw new Error(`Error saving phone number: ${error.message}`);
                });
    
            navigate('/home');
        } catch (error) {
            alert('Sign up failed. Please try again. ' + error.message);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.logoContainer}>
                <img style={styles.tokeLogo} src={tokeLogo} alt='toke logo'/>
            </div>

            <label style={styles.label}>First Name</label>
            <input 
                className='input' 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
            />

            <label style={styles.label}>Last Name</label>
            <input 
                className='input' 
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
            />

            <label style={styles.label}>Email</label>
            <input 
                className='input' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
            />
            <label style={styles.label}>Phone Number</label>
            <input 
                className='input' 
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="tel"
            />

            <label style={styles.label}>Address</label>
            <input 
                ref={addressInputRef} 
                placeholder=''
                className='input' 
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
            <label style={styles.label}>Password</label>
            <input 
                placeholder=''
                className='input' 
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <label style={styles.label}>Confirm Password</label>
            <input 
                placeholder=''
                className='input' 
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <p style={{ marginTop: '10px', marginBottom: '10px', fontSize: '11px' }}> By creating an account with Toke, you agree to our <a href='/terms' target='_blank' >terms and conditions</a>.
      </p>

            <button style={styles.signUpBtn} onClick={handleEmailSignUp}>Let's Go</button>
            <button style={styles.signInText} onClick={() => navigate('/signin')}>Already have an account? Sign In</button>
            <button onClick={handleGoogleSignIn} style={styles.googleButton}><img
                src={googleLogo} // Replace with your Google logo image path
                style={styles.googleLogo}
                alt="Sign in with Google"
            /></button>
            <GoToTop />
        </div>
    );
};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      paddingTop: '100px',
      width: '50%',
      margin: '0 auto'
    },
    input: {
      border: '1px solid black !important', // Black underline
      //padding: '10px',
      //margin: '5px',
      width: '80%',
      height: '30px',
      border: 'none', // Removes the box styling
      outline: 'none', // Optional: removes the highlight border on focus
  },
  label: {
      fontSize: '16px',
      color: '#000',
      marginTop: '10px',
      alignSelf: 'flex-start', // Aligns label to the top left
  },
    signInText: {
      border: 'none',
      fontSize: '12px',
      marginTop: '12px',
     
      color: '#333',
      cursor: 'pointer',
      background: 'none',
    },
    logoContainer: {
      marginBottom: '5px',
    },
    tokeLogo: {
      width: 150,
      height: 'auto'
    },
    signUpBtn: {
      width: '150',
      backgroundColor: '#FF6D6D',
      textAlign: 'center',
      padding: '15px',
      marginTop: 15,
      borderRadius: '33px',
      border: '1px solid white',
      color: 'white',
      cursor: 'pointer',
        fontWeight: '500',
    fontSize: '14px',
    },
    googleButton: {
      marginTop: '20px',
      border: 'none',
      cursor: 'pointer',
      background: 'none',
    },
    googleLogo: {
      width: '30px', // Adjust as needed
      height: '30px', // Adjust as needed
      cursor: 'pointer',
      background: 'none',
  },
};

export default SignUp;