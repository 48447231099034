import React from 'react'

const Search = () => {
  return (
    <div>
      search
    </div>
  )
}

export default Search
