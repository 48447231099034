import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppNavigator from './AppNavigator';
import AuthStack from './AuthStack'; // Adapt this for React Router
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const RootNavigator = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser ? authUser : null);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Routes>
        
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/*" element={<AppNavigator />} />
        
    </Routes>
  );
};

export default RootNavigator;
