import React, { useState } from 'react';
import { auth } from '../firebaseConfig'; // adjust the path as needed
import { sendPasswordResetEmail } from 'firebase/auth';
import GoToTop from '../Components/GoToTop';

const PasswordReset = () => {
    const [resetEmail, setResetEmail] = useState('');

    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            alert('Password reset email sent!');
            setResetEmail('');
        } catch (error) {
            alert('Error sending password reset email: ' + error.message);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.resetForm}>
                <h2 style={styles.title}>Reset Password</h2>
                <input
                    style={styles.input}
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="Enter your email"
                />
                <button style={styles.resetButton} onClick={handlePasswordReset}>
                    Send Reset Email
                </button>
            </div>
            <GoToTop />
        </div>
    );
};

// Styles
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f7f7f7'
    },
    resetForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: 'white'
    },
    title: {
        marginBottom: '20px',
        color: '#333',
        fontFamily: 'Arial, sans-serif'
    },
    input: {
        width: '300px',
        height: '40px',
        marginBottom: '20px',
        padding: '0 10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        fontSize: '16px'
    },
    resetButton: {
        width: '300px',
        height: '40px',
        backgroundColor: '#FF6D6D',
        color: 'white',
        border: 'none',
        borderRadius: '33px',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    }
};

export default PasswordReset;
